import dynamic from "next/dynamic";
const About = dynamic(() => import("./About"));
const Directors = dynamic(() => import("./Directors"));
const Meetings = dynamic(() => import("./Meetings"));
const Article = dynamic(() => import("./Article"));
const Management = dynamic(() => import("./Management"));
const Sustain = dynamic(() => import("./Sustain"));
const Subscribe = dynamic(() => import("./Subscribe"));
const Contact = dynamic(() => import("./Contact"));
const PressReleases = dynamic(() => import("./PressReleases"));
const ReleaseArticle = dynamic(() => import("./ReleaseArticle"));
const Disclaimer = dynamic(() => import("./Disclaimer"));
const DisclaimerContent = dynamic(() => import("./DisclaimerContent"));
const Ipo = dynamic(() => import("./Ipo"));
const Reports = dynamic(() => import("./Reports"));
const Calendar = dynamic(() => import("./Calendar"));
const Share = dynamic(() => import("./Share"));

const Containers = {
  About,
  Directors,
  Meetings,
  Article,
  Management,
  Sustain,
  Subscribe,
  Contact,
  PressReleases,
  ReleaseArticle,
  Disclaimer,
  DisclaimerContent,
  Ipo,
  Reports,
  Calendar,
  Share
};

export default Containers;
