
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import content from '../frontaid.content.json';
import containers from './../containers';
import serverside from '../serverside';
import getT from 'next-translate/getT';
import { isServer } from '../helpers/common';
import Common from "./../libs/Common";
import { AppContext } from './../helpers/AppContext';

function Page({page, initialprops}) {
  if (page.notfound) {
    if(process.env.APPENV === 'local') {
      return `currentPath :- ${page.currentPath}`;
    } else if(process.env.APPENV === 'acpt' || process.env.APPENV === 'acptclone' ) {
      return `currentPath :- ${page.currentPath}`;
    } else {
      if (!isServer) {
        if (typeof window !== "undefined") {
          window.location.href = window.location.pathname + window.location.search;
          return null;
        } else {
          return null;
        }
      } else {
        return null;
      }
    }
  } else {
    var Page = containers[`${page.container}`]
    return (
      <>
      <AppContext.Provider value={initialprops}>
        <Page {...initialprops}/>
      </AppContext.Provider>
      </>
    );
  }
 
}

 async function _getServerSideProps(context) {
  const { params, locale, req } = context;
  // session(req, res);

  var ioredis = require('ioredis');
  
  if(typeof global.redis === 'undefined') {
    global.redis = await new ioredis(process.env.REDIS_URL);
  }

  const newContext = {
    ...context,
    redis: global.redis

  }



  var QueryString = "";
  var isfullurl = false;
  if(typeof req.headers["pageargs"] === "string") {
    let headerQueryObj = JSON.parse(
      '{"' +
        decodeURI(
          req.headers["pageargs"].replace(/&/g, '","').replace(/=/g, '":"')
        ) +
        '"}'
    );
    QueryString = `?${serialize(headerQueryObj)}`;
  } else {

    isfullurl = true;
    QueryString = req.url;
  }
  
  let currentPath = `/${params.slug.join('/')}/`;
  if(isfullurl) {
    currentPath = QueryString;
  } else {
    currentPath = `${currentPath}${QueryString}`;
  }
  
  try{
      currentPath=decodeURIComponent(escape(currentPath));
  } catch(e){
      currentPath=currentPath;
  }



  const t = await getT(locale, 'lang_route');
  
 

  let page = content.pages.find(page => {
    
    const routeSlug = '.+';
    const pagestring = t(page.path, {
        slugname: routeSlug,
        slug: routeSlug,
        authorslug: routeSlug,
        bookslug: routeSlug,
        magazineslug: routeSlug,
        catslug: routeSlug,
        seriesslug: routeSlug,
        keyword: routeSlug,
        giftId: routeSlug,
        variant: locale
      }
    )
    const pageRegX = new RegExp(pagestring, "i");
    let matchArray;
    let isMatch = false;
    while ((matchArray = pageRegX.exec(currentPath)) !== null) {
      isMatch = matchArray[0] === currentPath;
      let match0Replace = String(matchArray[0])
        .replace("(", "\\(")
        .replace(")", "\\)")
        .replace("[", "\\[")
        .replace("]", "\\]")
        .replace("{", "\\{")
        .replace("}", "\\}")
        .replace("%", "\\%")
        .replace("\\/", "\\/");
        // .replace(/\//g, "");

      const pageRegX2 = new RegExp(`${match0Replace}\\?.+`, "i");
      if(pageRegX2.test(currentPath)) {
        isMatch = true;
      }
      break;
    }
    return isMatch; 
  }) || {notfound: true, currentPath: currentPath};

  let initialprops  = await Common(newContext);
  const serverContext = {...newContext, ...initialprops};
  

  if (typeof page.notfound === 'undefined') {
    page.notfound = false;
    page.currentPath = currentPath;
    let serverprops = await serverside[`${page.container}`](serverContext);
    initialprops = {...initialprops, ...serverprops};
  } else {
    if (!page.notfound) {
      page.currentPath = currentPath;
      let serverprops = await serverside[`${page.container}`](serverContext);
      initialprops = {...initialprops, ...serverprops};
    }
  }
  return {props: {page, initialprops}};
}


export default Page

    export async function getServerSideProps(ctx) {
        let res = _getServerSideProps(ctx)
        if(typeof res.then === 'function') res = await res
        return {
          
          ...res,
          props: {
            ...(res.props || {}),
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/[...slug]',
              loaderName: 'getServerSideProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  